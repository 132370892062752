import React, {useEffect} from "react"

export default function PostCode(props) {
  useEffect(() => {
    const element_wrap = document.getElementById('wrap');
    new window.daum.Postcode({
      oncomplete: function(data) {
        if(window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(data.address);
        } else {
          window.postMessage(data.address);
        }
        
      },
      width : '100%',
      height : '100%',            
    }).embed(element_wrap);  

    element_wrap.style.display = 'block';
  }, []);
  
  return (
    <main>
      <div id="wrap" style={{display:"none",width:"100%",height:"900px",margin:"5px 0"}}></div>
    </main>
  )
}
